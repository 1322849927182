import * as API from '@/api/index'

export default {
    //项目列表
    skinCondition:params =>{
        return API.POST('api/skinCondition/all',params)
    },
    //创建检测项目
    create:params =>{
        return API.POST('api/skinCondition/create',params)
    },
    // 编辑检测项目
    update:params =>{
        return API.POST('api/skinCondition/update',params)
    },

}